<template>
  <div class="mt-3">
  <!--  <v-row>
      <v-col lg="2">
        <s-text
          decimal
          ref="SquUnits"
          label="Cantidad (Jabas)"
          v-model="item.SquUnits"
           @input="inputPercentage($event)"
        />
      </v-col>
      <v-col lg="2">
        <s-text
          decimal
          label="Porcentaje (%)"
          v-model="item.SquPercentage"
          
          disabled
        />
      </v-col>
      
    </v-row>-->
    <v-row>
      <v-col class="pt-0">
        <v-row class="mt-1">
          <v-col lg="2"
            >
            <h3>Cantidades: {{ totalDamageItems }}</h3>
             
            </v-col
          >
          <v-row>
            <v-col>
              <v-spacer></v-spacer>
              <v-btn
                v-if="isEditHead"
                class="mr-3 elevation-5"
                x-small
                fab
                color="error"
                @click="clearQualityDamageHead()"
              >
                <i class="fas fa-clear"></i>
              </v-btn>
              <v-btn
                v-if="isEditHead"
                class="mr-3 elevation-5"
                x-small
                fab
                color="success"
                @click="saveQualityDamageHead()"
              >
                <i class="fas fa-edit"></i>
              </v-btn>
              <v-btn
                v-else
                class="mr-3 elevation-5"
                x-small
                fab
                color="primary"
                @click="saveQualityDamageHead()"
                ><i class="fas fa-plus"></i
              ></v-btn>
            </v-col>
          </v-row>
          <!--<v-col lg="2" class="pt-0" v-for="i in itemsTypeSampleQuality">
            <s-text
              :label="isEdit ? i.TypeQualityName : i.DedDescription"
              v-model="i.SqdValue"
              decimal
              @input="
                total = 0;
                itemsTypeSampleQuality.forEach((item) => {
                  total = (total + parseInt(item.SqdValue == null || item.SqdValue == '' ? 0 : item.SqdValue));});">
            </s-text>
          </v-col>samir-->
          <v-row v-if="TypeCultive == 1">
          <v-col>
            <s-text 
              v-model="firstFruit"
              decimal
              label="Primera"/>  
          </v-col>
          <v-col>
            <s-text 
              v-model="secondFruit"
              decimal
              label="Segunda"/>  
          </v-col>
          <v-col>
            <s-text 
              v-model="bigFruit"
              decimal
              label="Grande"/>  
          </v-col>
          <v-col>
            <s-text 
              v-model="smallFruit"
              decimal
              label="Pequeña"/>  
          </v-col></v-row>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" lg="3" md="4" v-for="item in Object.keys(itemsTypeDamage)">
        <h4>{{ item }}</h4>
        <v-divider></v-divider>
        <v-col  class="pt-5 pr-0">
          <v-row v-for="d in itemsTypeDamage[item]">
            <v-col cols="7" class="pb-0 pt-1 pr-0 pl-0">
              <s-text :label="d.SdpDescription" v-model="d.SddValue" decimal />
            </v-col>
            <v-col cols="5" class="pb-0 pt-1 pr-0 pl-0"
              ><s-select-definition
                label="."
                :def="1264"
                v-model="d.TypeIntensity"
            /></v-col>
          </v-row>
        </v-col>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12">
        <v-data-table
          :headers="headersQualityDamageHead"
          :items="itemsQualityDamageHead"
          v-model="selectedItemsQualityDamageHead"
          @click:row="rowSelected"
          item-key="SqhID"
          dense
        >
          <template v-slot:item.accion="{item}">
            <v-btn
              x-small
              color="error"
              @click="deleteQualityDamageHead(item)"
            >
              <i class="fas fa-trash"></i>
            </v-btn>
            <v-btn
              x-small
              color="warning"
              @click="editQualityDamageHead(item)"
            >
              <i class="fas fa-edit"></i>
            </v-btn>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
     
   <!--  <v-row>
      <v-spacer></v-spacer>
      <v-col lg="3">
        <v-btn block rounded color="warning" outlined @click="$emit('close')"
          >Cancelar</v-btn
        >
      </v-col>
      <v-col lg="3">
        <v-btn block rounded color="primary" @click="showAlert = true"
          >Guardar</v-btn
        >
      </v-col>
    </v-row> -->

    <v-dialog permanent v-model="showAlert" width="500">
      <v-card>
        <v-col>
          <v-row>
            <h2 class="mt-6 ml-3">Alerta</h2>
            <v-spacer></v-spacer>
            <v-btn
              class="mt-3 mr-3"
              @click="showAlert = false"
              small
              fab
              color="error"
              ><i style="font-size: 16px" class="fas fa-times"></i
            ></v-btn>
          </v-row>

          <v-row>
            <v-col class="pb-0">
              <s-select-definition
                :def="1312"
                label="Alerta"
                v-model="item.TypeAlert"
              ></s-select-definition>
            </v-col>

            <v-col cols="12">
              <s-textarea label="Observación" v-model="item.SquObservation">
              </s-textarea>
            </v-col>
          </v-row>
          <v-col>
            <v-row justify="center" class="pt-3">
              <v-spacer></v-spacer>
              <v-btn @click="showAlert = false" color="default">Cancelar</v-btn>
              <v-btn class="ml-3" dark color="primary" @click="save()"
                >Guardar</v-btn>
            </v-row>
          </v-col>
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import _sSampleDamageCultive from "@/services/FreshProduction/SampleDamage/SampleDamageCultive.js";
import _sDefinition from "@/services/General/DefinitionService.js";
import _sDamage from "@/services/FreshProduction/SampleDamage/SampleQuality";

import _sParameter from "@/services/General/ParameterService.js";

export default {
  name: "BrixFresh",
  components: {},
  props: {
    dataSampleDamage: {},
    TypeCultive: 0
  },
  data: () => ({
    firstFruit: 0,
    secondFruit: 0, 
    bigFruit: 0,
    smallFruit: 0,
    sampledamagesc:{},
   
    headDamage: {},
    selectedItemsQualityDamageHead: [],
    total: 0,
    totalAux: 0,
    showAlert: false,
    itemsTypeDamage: [],
    arrayIntensitys: [],
    itemsTypeSampleQuality: [],
    itemsIntensityDamage: [],
    item: {
      SquUnits: null,
      UnitMeasurement: null,
      SquAwning: null,
      SquCleaning: null,
      SquObservation: null,
    },
    itemsQualityDamageHead: [],
    itemsQualityDamageDetail: [],
    headersQualityDamageHead: [
      {text: "Accion", value: "accion", width: 250},
      {text: "ID", value: "SqhID"},
      {text: "Total Daño", value: "TotalDamage"},   			 
      {text: "Primera", value: "FirstFruit"},    			 
      {text: "Segunda", value: "SecondFruit"},    			 
      {text: "Grande", value: "BigFruit"},    			 
      {text: "Pequeña", value: "SmallFruit"},    			   	
    ],
    isEditHead: false,
    SqhIDEdit: 0,
    SquIDEdit: 0,
    totalDamageItems: 0,
  }),

  watch: {
    dataSampleDamage(){
       //this.initialize();
      console.log("WATCH mounted los datos del capataz", this.dataSampleDamage);
      this.getListQualityDamageHead();


      //samir
      this.getDamageCultive();

    }
  },
   
  methods: {
   
    clearQualityDamageHead(){
      if(this.isEditHead){
                this.isEditHead = false;
              }
              this.getListQualityDamageHead();
              this.itemsQualityDamageDetail = [];
              this.getDamageCultive();
    },
    editQualityDamageHead(item)
    {
      console.log("item head damage", item);

      this.bigFruit = item.BigFruit;
      this.firstFruit = item.FirstFruit;
      this.secondFruit = item.SecondFruit;
      this.smallFruit = item.SmallFruit;

      console.log("this.bigFruit", this.bigFruit);
      console.log("this.FirstFruit", this.FirstFruit);
      console.log("this.SecondFruit", this.SecondFruit);
      console.log("this.SmallFruit", this.SmallFruit);

      _sDamage
      .searchbyhead(item.SqhID, this.$fun.getUserID())
      .then(resp => {
        if(resp.status == 200){
          if(resp.data.length > 0){
            this.isEditHead = true;
          }
          this.SqhIDEdit = resp.data[0].SqhID;
          this.SquIDEdit = resp.data[0].SquID;
          this.itemsTypeDamage = _.groupBy(resp.data, "TypeDamageName");
          
         
          //samir
          console.log("itemsTypeDamage  ", resp.data);
          console.log("itemsTypeDamage  ", _.groupBy(resp.data, "TypeDamageName"));
          console.log("itemsTypeDamage  ", this.itemsTypeDamage);
          console.log("SquIDEdit samir", this.SquIDEdit);
        }
      });
    },

    rowSelected(item, row)    
    {
      this.selectedItemsQualityDamageHead = [item];
    },

    getListQualityDamageHead()
    {
      _sDamage
      .listhead({
        RcfID: this.dataSampleDamage.RcfID, 
        RcfGngID: this.dataSampleDamage.RcfGngID
        }, this.$fun.getUserID())
        .then(resp => {
          if(resp.status == 200){
            console.log('datos que me trae', resp.data)
            this.itemsQualityDamageHead = resp.data;
            //totalDamageItems
            this.totalDamageItems = 0;
            this.itemsQualityDamageHead.forEach(element => {
              this.totalDamageItems = this.totalDamageItems + element.TotalDamage;
            })
          }
        });
    },

    deleteQualityDamageHead(item)
    {
      console.log("item", item);
      item.SecStatus = 0;
      item.UsrUpdateID = this.$fun.getUserID();
      _sDamage
      .savehead(item, this.$fun.getUserID())
      .then(resp => {
        if(resp.status == 200){
          this.$fun.alert("Actualizado Correctamente", "success");
          this.getListQualityDamageHead();
        }
      })
    },

    saveQualityDamageHead()
    {
      let head = {};
      console.log("head samir", head);

      head.firstFruit = parseInt(this.firstFruit);
      head.secondFruit = parseInt(this.secondFruit); 
      head.bigFruit = parseInt(this.bigFruit);
      head.smallFruit = parseInt(this.smallFruit);
       
      if(!this.isEditHead){

        //insert
        for (let prop in this.itemsTypeDamage)
        this.itemsQualityDamageDetail.push(...this.itemsTypeDamage[prop]);
        
           
        head.SquID = null;
        head.SecStatus = 1;
        head.UsrCreateID = this.$fun.getUserID();
        head.UsrUpdateID = this.$fun.getUserID();
        head.RcfID = this.dataSampleDamage.RcfID;
        head.PrfSampleQualityDamage = this.itemsQualityDamageDetail;
         
      }else{
        //editar
        for (let prop in this.itemsTypeDamage)
        this.itemsQualityDamageDetail.push(...this.itemsTypeDamage[prop]);
          
        head.SqhID = this.SqhIDEdit;
        head.SquID = this.SquIDEdit;
        head.SecStatus = 1;
        head.UsrCreateID = this.$fun.getUserID();
        head.UsrUpdateID = this.$fun.getUserID();
        head.RcfID = this.dataSampleDamage.RcfID;
        head.NtpID = this.dataSampleDamage.RcfGngID;
        head.PrfSampleQualityDamage = this.itemsQualityDamageDetail;
         
      }

      console.log("head samir", head);
       
      this.$fun.alert("¿Seguro de agregar registro ?", "question").then(val => {
        if(val.value){
          head.NtpID = this.dataSampleDamage.RcfGngID;
          _sDamage
          .savehead(head, this.$fun.getUserID())       
          .then(resp => {
            if(resp.status == 200){
              this.$fun.alert("Agregado Correctamente", "success");
              if(this.isEditHead){
                this.isEditHead = false;
              }
              this.getListQualityDamageHead();
              this.itemsQualityDamageDetail = [];
              this.getDamageCultive();

              this.firstFruit = 0;
              this.secondFruit = 0; 
              this.bigFruit  = 0;
              this.smallFruit = 0;

            }
          })

      
        }
      })



    },

    inputPercentage(value) {
      if (this.objForeman)
        this.item.SquPercentage = parseFloat(
          (parseFloat(100) * (value == null || value == "" ? parseFloat(0) : parseFloat(value))) / parseFloat(this.objForeman.FrmQuantityReal)
        );
    },
    
    save() {
      if (this.item.SquUnits == null) {
        this.$refs.SquUnits.error("Ingrese Unidades Muestreadas");
        return;
      }

      this.item.PrfSampleQualityDamage = [];
      this.item.PrfSampleQualityDetail = [];

      for (let prop in this.itemsTypeDamage)
        this.item.PrfSampleQualityDamage.push(...this.itemsTypeDamage[prop]);

      //DEATALLES DE CALIDAD
      this.item.PrfSampleQualityDetail.push(...this.itemsTypeSampleQuality);

      this.item.NtpIDForeman = this.objForeman.RcfGngID;
      this.item.RcfID = this.lot.RcfID;
      this.item.SecStatus = 1;
      this.item.SquStatus = 1;
      this.item.UsrUpdateID = this.$fun.getUserID();
      this.item.UsrCreateID = this.$fun.getUserID();



      //this.item.SquVb = this.items.BrxVb;

     /*  _sDamage
      .save(this.item, this.$fun.getUserID())
      .then(re => {
        if (re.status == 200) { */
          /* this.$fun.alert(
            "Registrado correctamente",
            "success",
          ); */
          //this.$emit("close");
        /* }
      }); */
      this.$emit('responseDataDamage');
      this.$emit("saved", this.item);
      
    },

    getDamageCultive()
    {
      //this.lot.TypeCultive
      _sSampleDamageCultive
      .list(this.dataSampleDamage.TypeCultive, this.$fun.getUserID())
      .then((r) => {
        console.log('traigo los daños de los cultivos')
        let itemsDamage = r.data;
        this.itemsTypeDamage = _.groupBy(itemsDamage, "TypeDamageName");
        for (let prop in this.itemsTypeDamage) {
          this.itemsTypeDamage[prop].map((i) => {
            i.TypeIntensity = 1;
            i.SddValue = 0;
            i.SddStatus = 1;
            i.UsrCreateID = this.$fun.getUserID();
            i.UsrUpdateID = this.$fun.getUserID();
          });
        }
      });
      
    },

    initialize() {

      if (this.objEdit != null) {
        this.total = 0;
        this.item = Object.assign(this.objEdit, {});

        this.itemsTypeSampleQuality = this.item.PrfSampleQualityDetail;

       /** this.itemsTypeSampleQuality.forEach(element => {
          this.total = parseInt(this.total) + parseInt(element.SqdValue)
        }) */

        console.log("inciiar", this.total, this.totalAux);
        
        this.getDamageCultive();
        /* this.itemsTypeDamage = _.groupBy(
          this.item.PrfSampleQualityDamage,
          "TypeDamageName"
        ); */
      } else {
        this.item.SquID = null;
        this.item.SquUnits = 0
        this.itemsTypeDamage = {};
        this.itemsTypeSampleQuality = [];
        this.item.SquPercentage = 0//this.percentageSample;
        this.inputPercentage(0); //this.percentageSample
        this.getDamageCultive();
        _sDefinition
          .definitiongroup(1287, 0, this.$fun.getUserID())
          .then((r) => {
            this.itemsTypeSampleQuality = r.data;
            this.itemsTypeSampleQuality.map((x) => {
              x.SqdValue = "";
              x.SqdStatus = 1;
              x.TypeQuality = x.DedValue;
              x.UsrCreateID = this.$fun.getUserID();
              x.UsrUpdateID = this.$fun.getUserID();
            });
          });
      }
    },
  },
 
  mounted(){
    //this.initialize();
     console.log("mounted los datos del capataz", this.dataSampleDamage);
    this.getListQualityDamageHead();


    //samir
    this.getDamageCultive();
        //console.log("this.sampleRcfID", this.sampleRcfID);
     // this.sampledamagesc.SquObservation = this.sampleRcfID.SquObservation;
      //this.sampledamagesc.SquCorrectiveActions = this.sampleRcfID.SquCorrectiveActions;
  },
  created(){
    console.log("created del sample damage");
  }
};
</script>
