import Service from "../Service";
import Vue from "vue";
const resource = "/ProgrammingStorage/";

export default {

    GetDetails(obj, requestID) {
        return Service.post(resource + "GetReceptionDetail", obj, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "paginationReception", parameters, {
            params: { requestID: requestID },
        });
    },
 
    save(obj, requestID) {
        return Service.post(resource + "saveItem", obj, {
            params: { requestID: requestID },
        });
    },

    removeItem(obj, requestID) {
        return Service.post(resource + "removeItem", obj, {
            params: { requestID: requestID },
        });
    }

};