import Service from "../Service";

const resource = "BrixFresh/";

export default {

    getColorForVariety(vrtID, requestID) {
        return Service.post(resource + 'getColorForVariety', {}, {
            params: { requestID: requestID, vrtID: vrtID },
        });
    },

    save(cmp, requestID) {
        return Service.post(resource + "save", cmp, {
            params: { requestID: requestID },
        });
    },

    getBrixGuide(obj = {}, requestID) {
        return Service.post(resource + "getBrixGuide", obj, {
            params: { requestID: requestID },
        });
    },
    getbyforeman(obj = {}, requestID) {
        return Service.post(resource + "getbyforeman", obj, {
            params: { requestID: requestID },
        });
    },
};