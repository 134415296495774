<template>
	<div>
		<v-card class="mt-0 pt-0" lg="6">
			<!-- Inicio Campos de Alerta -->
			<v-alert
				v-model="alert"
				border="right"
				:color="'#9EB8F6'"
				dark
				dense
				shaped
				close-icon="fas fa-times-circle"
				style="border-radius: 10px 10px 10px 10px; z-index: 5; width: 100%; right: 0px; top: 5%; margin-top: -22px;}"
			>
				<div>
					<v-row justify="center">
						<v-col lg="3">
							<b>Capataz:</b> {{ viewItemsGang.FrmName }}
						</v-col>
						<v-col lg="3">
							<b>Productor:</b> {{ viewItemsGang.PrdCardName }}
						</v-col>
						<v-col lg="3">
							<b>Lote: </b>{{ viewItemsGang.FltName }}
						</v-col>
					</v-row>
					<v-row
						justify="center"
						style="margin-top: -20px; font-size: smaller"
					>
						<v-col lg="3">
							<b>Tipo Cultivo:</b>
							{{ viewItemsGang.TypeCropName }}
						</v-col>
						<v-col lg="3">
							<b>Cultivo:</b> {{ viewItemsGang.TypeCultiveName }}
						</v-col>
						<v-col lg="3">
							<b>Variedad: </b>{{ viewItemsGang.VrtName }}
						</v-col>
					</v-row>
				</div>
			</v-alert>

			<v-row justify="center">
				<!-- Inicio Campos para Calcilo de Pesado -->
				<div class="col-md-7 col-sm-12">

					

					<v-col
						cols="12"
						v-if="viewItemsGang.FrmQuantityReal == sumTotalJaba"
						align="center"
					>

						 <v-alert
							v-model="viewItemsGang.FrmQuantityReal == sumTotalJaba ? true: false"
							border="right"
							:color="'success'"
							dark
							dense
							dismissible
							shaped
							close-icon="fas fa-times-circle"
							style="border-radius: 10px 10px 10px 10px; overflow: auto; z-index: 9999999; position: fixed; width: 40%; right: 30%; top: 40%;"
							>
								<b style="color: white; font-family: Courier"
									><h2>
										{{
											viewItemsGang.FrmQuantityReal ==
											sumTotalJaba
												? "Atención !!! Cantidad de jabas Pesadas es igual a Jabas recepcionadas"
												: ""
											
										}}
									</h2></b
								>
							</v-alert>
						
					</v-col>
					<v-row class="ml-2 mr-2">
						<!-- <v-col cols="12" lg="3" style="margin-bottom: inherit">
							<s-select-definition
								full
								label="Seleccione Puerta"
								:def="1251"
								v-model="items.TypeDoor"
								@input="inputDoor($event)"
							>
							</s-select-definition>
						</v-col> -->

						<v-col cols="12" lg="4">
							<v-select
								:items="itemsDoorAll"
								item-value="TypeDoor"
								item-text="TypeDoorName"
								label="Seleccione Puerta"
								v-model="items.TypeDoor"
								@input="inputDoor($event)"
							>
								<template v-slot:item="{ item }">
									{{ item.TypeDoorName }}
									<v-chip
										x-small
										:color="
											item.inProcess == 0
												? 'info'
												: 'error'
										"
										style="margin-left: 5px"
										>{{
											item.inProcess == 0
												? "Libre"
												: "Ocupada"
										}}</v-chip
									>
								</template>
							</v-select>
						</v-col>

						<v-col cols="12" lg="4">
							<s-select
								:disabled="items.TypeDoor > 0 ? false : true"
								:items="itemsNumberGuide"
								item-value="RcfID"
								full
								autocomplete
								item-text="RcfNumberHarvestGuide"
								label="Seleccione Nro. Guia de cosecha"
								v-model="RcfID"
								@input="inputNumberGuide($event)"
								:slotTmp="true"
							>
								<template v-slot:item="data">
									{{ data.item.RcfNumberHarvestGuide }}
									<v-chip
										x-small
										style="margin-left: 5px"
										:color="
											data.item.inProcessTerminated > 0
												? 'info'
												: data.item.inProcess > 0
												? 'warning'
												: 'error'
										"
										>{{
											data.item.inProcessTerminated > 0
												? "Pesado: " + data.item.TypeDoorName
												: data.item.inProcess > 0
												? "En Proceso: " + data.item.TypeDoorName
												: "Sin Pesar"
										}}</v-chip
									>
								</template>
							</s-select>
						</v-col>

						<v-col cols="12" lg="4">
							<s-select
								:disabled="items.TypeDoor > 0 ? false : true"
								:items="itemsGang"
								item-value="RcfGngID"
								item-text="FrmName"
								label="Seleccione Capataz"
								return-object
								v-model="objForeman.RcfGngID"
								@input="inputForeman($event)"
								full
							></s-select
							><span>
								Cant. Jabas Recepcionadas:
								<b style="font-size: 16px">{{
									viewItemsGang.FrmQuantityReal
								}}</b>
							</span>
						</v-col>

						<v-col cols="12" lg="12" style="margin-top: -20px">
							<v-text-field
								label=" Peso Prom. Jaba.:"
								v-model="RcfWeightProm.toFixed(4)"
								readonly
								:error="
									RcfWeightProm < valParamMin ||
										RcfWeightProm > valParamMax
								"
								:messages="
									RcfWeightProm < valParamMin ||
									RcfWeightProm > valParamMax
										? 'Peso Promedio fuera de Param. establecidos. (Min: ' +
										  valParamMin +
										  ', Max: ' +
										  valParamMax +
										  ')'
										: ''
								"
							>
							</v-text-field>
						</v-col>

						<v-col cols="12" lg="12">
							<v-row style="border-top: groove">
								<v-col cols="6" lg="6" md="6">
									<s-text
										style="width: 95%"
										label="Cant. Jabas Pesar"
										number
										v-model="items.WpbJabasQuantity"
										ref="focusJabasQuantity"
									></s-text>
								</v-col>

								<v-col
									cols="6"
									lg="6"
									md="6"
									style="display: flex"
								>
									<s-text
										style="width: inherit"
										:label="'Peso ' + typeChargeName"
										decimal
										v-model="items.WpbWeightJaba"
										readonly
									/>
									<span
										style="height: fit-content; margin-top: 21px"
										>KG</span
									>
								</v-col>

								<v-col
									cols="6"
									lg="6"
									md="6"
									style="margin-top: -15px; display: flex"
								>
									<s-text
										style="width: inherit"
										label="Peso Bruto"
										decimal
										ref="focusWeightGross"
										v-model="items.WpbWeightGross"
									/>
									<span
										style="height: fit-content; margin-top: 21px"
										>KG</span
									>
								</v-col>

								<v-col
									cols="6"
									lg="6"
									md="6"
									style="margin-top: -15px; display: flex"
								>
									<s-text
										style="width: inherit"
										label="Peso Parihuela"
										decimal
										v-model="items.WpbWeightPallet"
										ref="focusWeightPallet"
									/>
									<span
										style="height: fit-content; margin-top: 21px"
										>KG</span
									>
								</v-col>
							</v-row>

							<v-row justify="center">
								<v-col
									cols="12"
									lg="6"
									md="6"
									style="margin-top: -15px; display: flex"
								>
									<s-text
										style="width: inherit"
										label="Peso Neto"
										decimal
										v-model="GetWeightNetPallet"
										readonly
									></s-text>
									<span
										style="height: fit-content; margin-top: 21px"
										>KG</span
									>
								</v-col>

								<v-col
									cols="6"
									lg="3"
									md="3"
									style="margin-top: 0px"
								>
									<v-btn
										:disabled="disabledBtn"
										style="width: 100%"
										color="primary"
										@click="Grabar()"
										>Grabar
									</v-btn>
								</v-col>
								<v-col
									cols="6"
									lg="3"
									md="3"
									style="margin-top: 0px"
								>
									<v-btn
										style="width: 100%"
										color="warning"
										@click="clearAll()"
										>Cancelar
									</v-btn>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</div>

				<div class="col-md-5 col-sm-12">
					<v-col cols="12">
						<v-card outlined>
							<v-row style="margin:auto">
								<v-col cols="6">
									<s-text readonly label="Cant. Jabas" v-model="sumTotalJaba"></s-text>
								</v-col>
								<v-col cols="6">
									<s-text v-model="sumTotalParihuela" readonly label="Peso Parihuela (KG)"></s-text>
								</v-col>
								<v-col cols="6">
									<s-text v-model="sumTotalWeightGross" readonly label="Peso Bruto (KG)"></s-text>
								</v-col>
								<v-col cols="6">
									<s-text v-model="sumTotalWeightNet" readonly label="Peso Neto (KG)"></s-text>
								</v-col>
							</v-row>
						</v-card>
					</v-col>
					<v-col cols="12">
						<v-card outlined>
							<v-row style="margin:auto">
								<v-col cols="12">
									<h3 style="color: red">Control de Peso Lote</h3>
								</v-col>
								<v-col cols="6">
									<s-text v-model="sumWeightAverage" readonly label="Peso Promedio Jaba con Fruta (KG)"></s-text>
								</v-col>
								<v-col cols="6">
									<s-text v-model="sumTotalWeightNet" readonly label="Total Peso Neto (KG)"></s-text>
								</v-col>
							</v-row>
						</v-card>
					</v-col>
					
				</div>


				<!-- Fin Campos para Calculo de Pesado -->
			</v-row>

			<!-- Inicio Listado de Pesos -->
			<v-row style="margin-top: -15px">
				<div class="col-md-12 col-sm-12">
					<s-toolbar
						label="Listado de Pesos"
						dark
						:color="'#BAB6B5'"
						class="mr-4 ml-2"
						style="display: flex"
						edit
						remove
						@edit="edit()"
						@removed="remove()"
						print
						@print="printQR()"
					></s-toolbar>
					<v-row class="ml-4">
						<v-col cols="12" lg="12">
							<v-data-table
								item-key="WpbID"
								:headers="headersWeighed"
								:items="itemsWeighed"
								v-model="selectedWeighed"
								dense
								hide-default-footer
								:items-per-page="-1"
								@click:row="rowClickWeighed"
							>
							</v-data-table>
						</v-col>
						<v-col>
							<p>
								Cantidad Recepcionada:
								<b> {{ sumTotalJaba }}</b>
							</p>
						</v-col>
					</v-row>
				</div>
			</v-row>
			<!-- Fin Listado de pesoso -->
			<v-col cols="12" style="display: none">
				<qr-code :text="textQR" error-level="H" hidden> </qr-code>
			</v-col>
		</v-card>
	</div>
</template>

<script>
	import _sReceptionFresh from "@/services/FreshProduction/ReceptionFreshService";
	import _sWeighedByBatch from "../../../services/FreshProduction/WeighedByBatchService";
	import _sGenParamService from "../../../services/General/ParameterService";
	import qrCode from "vue-qr-generator";

	export default {
		props: {
			ship: {
				type: Number,
				required: true
			}
		},
		components: {
			qrCode
		},
		data: () => ({
			headersGang: [
				{ text: "Capataz", value: "FrmName", width: 250 },
				{ text: "Cant. Jabas Cosechadas", value: "FrmQuantityReal" }
			],

			headersWeighed: [
				{ text: "Fecha/Hora", value: "WpbDate", width: 150 },
				{ text: "Nro. Palet", value: "WpbNumberPallet", width: 80 },
				{ text: "Cant. Jabas", value: "WpbJabasQuantity", width: 100 },
				{ text: "Peso Jaba", value: "WpbWeightJaba", width: 100 },
				{ text: "Peso Parihuela", value: "WpbWeightPallet", width: 100 },
				{ text: "Peso Bruto", value: "WpbWeightGross", width: 150 },
				{ text: "Peso Neto", value: "WpbWeightNet", width: 100 },
				{
					text: "Peso Prom. Jaba",
					value: "WpbWeightAverageJaba",
					width: 100
				}
			],

			items: {
				WpbID: 0,
				WpbJabasQuantity: 40,
				WpbWeightGross: 0,
				/* WpbWeightJaba: 1.7, */
				WpbWeightPallet: 37
			},
			RcfID: 0,
			objForeman: {},
			itemsGang: [],
			selectedWeighed: [],
			itemsNumberGuide: [],
			viewItemsGang: {},

			itemsWeighed: [],
			
			alert: false,
			RcfWeightProm: 0,
			clickEdit: 0,
			valParamMin: 0,
			valParamMax: 0,
			typeChargeName: "",
			textQR: "Sin datos",
			objPriceJaba: {},
			disabledBtn: false,
			itemsDoorAll: [],


			sumTotalJaba: 0,
			sumTotalParihuela: 0,
			sumTotalWeightGross: 0,
			sumTotalWeightNet: 0,
			sumWeightAverage: 0
		}),

		methods: {
			//Cargar Parametros
			loadData() {
				//Cargar las Guia  de cosecha
				_sWeighedByBatch
					.getHarvestGuide(
						{ xPrint: 0, TypeShip: this.ship },
						this.$fun.getUserID()
					)
					.then(r => {
						//if (r.data.length > 0) {
						this.itemsNumberGuide = r.data;
						//}
					});

				_sWeighedByBatch.getDoorAll({TypeShip: this.ship}, this.$fun.getUserID()).then(r => {
					this.itemsDoorAll = r.data;
					/* if(this.itemsDoorAll.length > 0) {
							this.items.TypeDoor = this.itemsDoorAll[0];
						} */
				});
			},

			sumTotalWeight(){

				this.sumTotalParihuela = 0
				this.sumTotalWeightGross = 0
				this.sumTotalWeightNet = 0
				this.sumWeightAverage = 0
				this.itemsWeighed.forEach(element => {
					this.sumTotalParihuela += parseFloat(element.WpbWeightPallet)
					this.sumTotalWeightGross += parseFloat(element.WpbWeightGross)
					this.sumTotalWeightNet += parseFloat(element.WpbWeightNet)

					console.log("vamos a sumar", element);
				});

				this.sumWeightAverage = this.sumTotalWeightNet / this.sumTotalJaba
			},

			//Limpair todo
			clearAll() {
				this.items.WpbID = 0;
				this.items.WpbJabasQuantity = 40;
				this.items.WpbWeightGross = 0;
				this.items.WpbWeightJaba = this.objPriceJaba.TypeChargePrice;
				this.items.WpbWeightPallet = this.objPriceJaba.TypeChargeWeighedPal;
				this.getWeighedByBatch();
				this.clickEdit = 0;
			},

			//Seleccionar Fila de Pesada
			rowClickWeighed: function(item, row) {
				this.selectedWeighed = [item];
				console.log("this.selectedWeighed", this.selectedWeighed);
				this.sumTotalJaba = this.GetSumQuantityJabas();
				this.clickEdit = 0;
				//console.log("rowClickWeighed", this.selectedWeighed);

				/* this.selectedWeighed[0].WpbID.toString() +  */
				var cadTextQR =
					this.selectedWeighed[0].RcfID.toString() +
					", " +
					this.selectedWeighed[0].WpbID.toString() +
					", " +
					this.selectedWeighed[0].WpbJabasQuantity +
					", " +
					this.selectedWeighed[0].WpbNumberPallet +
					", LP-" +
					this.selectedWeighed[0].RcfID.toString() +
					", Cant. Jabas: " +
					this.selectedWeighed[0].WpbJabasQuantity +
					", PP. Jaba: " +
					this.selectedWeighed[0].WpbWeightAverageJaba +
					", PN: " +
					this.selectedWeighed[0].WpbWeightNet +
					", PB: " +
					this.selectedWeighed[0].WpbWeightGross +
					", " +
					this.selectedWeighed[0].WpbDate +
					", " +
					this.selectedWeighed[0].TypeDoorName;

				this.textQR = cadTextQR;
				
			},

			//Al seleccionar registro en el combo de Puerta, Recibimos el Id de la Puerta
			inputDoor(TypeDoor) {
				/* alert(TypeDoor) */
				if (TypeDoor !== undefined) {
					console.log("TypeDoor", TypeDoor, this.itemsNumberGuide);
					//Obtener los registros que estan siendo pesados en la puerta selecioanda
					//Si aun esta en proceso,

					/* if(this.itemsNumberGuide.length > 0) { */
					_sWeighedByBatch
						.getDoor(
							{ TypeDoor: TypeDoor, TypeShip: this.ship },
							this.$fun.getUserID()
						)
						.then(r => {
							if (r.data.length > 0) {
								console.log("door data", r.data);
								this.RcfID = r.data[0].RcfID; //Seleccionamos por Defecto la Guia de Cosecha
								this.objForeman.RcfGngID = r.data[0].RcfGngID; //Selecconar el Capataz
							} else {
								this.RcfID = 0;
								this.objForeman = {};
								this.viewItemsGang = {}
							}
						});

					if (this.itemsNumberGuide.length == 0) {
						this.RcfID = 0;
						this.objForeman = {};
					}
					/* } */
				}
			},

			//Al seleccionar registro en el combo Guia de Cosecha
			inputNumberGuide(RcfID) {
				console.log( "GUIA DE COSECHA samir", RcfID);
				if (RcfID !== 0 || RcfID !== undefined) {
					this.objPriceJaba = this.itemsNumberGuide.find(
						x => x.RcfID == RcfID
					);
					if (this.objPriceJaba !== undefined) {
						this.items.WpbWeightJaba = this.objPriceJaba.TypeChargePrice;
						this.items.WpbWeightPallet = this.objPriceJaba.TypeChargeWeighedPal;
						this.valParamMin = this.objPriceJaba.WpcMin;
						this.valParamMax = this.objPriceJaba.WpcMax;
						this.typeChargeName = this.objPriceJaba.TypeChargeName;

						if (this.valParamMin == null || this.valParamMax == null) {
							this.$fun.alert(
								"Advertencia, no se ha configurado parametros Peso Promedio " +
									this.typeChargeName +
									" (Min - Max)",
								"warning"
							);
							this.disabledBtn = true;
						} else {
							this.disabledBtn = false;
						}

						console.log("guia seleccionada", this.objPriceJaba);
					}

					//Cargar todos los capataz pertenecientes a es Guia de Cosecha seleccionada
					_sReceptionFresh
						.GetGang({ RcfID: RcfID }, this.$fun.getUserID())
						.then(r => {
							console.log("Capataz " ,r.data);
							this.itemsGang = r.data;
							this.itemsWeighed = [];
							this.viewItemsGang = {}
						});
				}
			},

			//Al seleccionar registro del combo de Capataz
			inputForeman(objForeman) {
				//alert(objForeman)
				console.log("objForeman", objForeman);
				if (objForeman !== undefined) {
					this.viewItemsGang = objForeman;
					//console.log("viewItemsGang", this.viewItemsGang);
					this.alert = true; //Mostramos la alerta
					this.getWeighedByBatch(); //Cargamos la Pesadas a ese Capataz
				}
			},

			//Obtener Pesadas
			getWeighedByBatch() {
				_sWeighedByBatch
					.getWeighedBatch(
						{
							RcfID: this.viewItemsGang.RcfID, //this.viewItemsGang.RcfID,
							RcfGngID: this.viewItemsGang.RcfGngID, //this.viewItemsGang.RcfGngID,
							TypeDoor: this.items.TypeDoor
						},
						this.$fun.getUserID()
					)
					.then(r => {
						this.itemsWeighed = r.data; //Registros de Pesada
						this.itemsWeighed.forEach(element => {
							element.WpbDate = this.$moment(element.WpbDate).format(
								this.$const.FormatDateTimeDB
							);
						});

						if(this.itemsWeighed.length > 0){
							let ob = this.itemsWeighed[0];
							this.selectedWeighed = [ob];
							this.rowClickWeighed(ob, ob);
							this.printQR();
							
						}
						
					});
			},

			//***************************** CALCULOS ******************************************* */

			//Calcular el peso promedio de Jaba
			GetWeightAverageJaba() {
				if (this.items !== undefined) {
					var WeightAverageJaba = 0;
					WeightAverageJaba =
						this.GetWeightNetPallet / this.items.WpbJabasQuantity;
				}

				return WeightAverageJaba;
			},

			//Sumar todas la Cantidad de jabas de la Grilla, Retorna la suma
			GetSumQuantityJabas() {
				var QuantityJabas = 0;
				this.itemsWeighed.forEach(element => {
					QuantityJabas = QuantityJabas + parseInt(element.WpbJabasQuantity);
				});

				return QuantityJabas;
			},

			//Calcular las Jabas restantes que aun faltan
			isDifferenceJabas(Amount) {
				var isValidated = true;
				var restante =
					this.viewItemsGang.FrmQuantityReal - this.sumTotalJaba;

				if (restante < Amount) {
					this.$fun.alert(
						"Esta superando la cantidad de jabas recepcionadas",
						"warning"
					);
					isValidated = false;
					return;
				}
				return isValidated;
			},

			//***************************** FIN CALCULOS ******************************************* */

			//*****************************  GUARDADO Y VALIDACIONES ******************************************* */

			//Validacion General
			isValidatedAll() {
				var isValidated = true;

				if (this.RcfID == null || this.RcfID == undefined) {
					this.$fun.alert(
						"Seleccione un Nro. Guia de cosecha",
						"warning"
					);
					return;
				}

				if (this.viewItemsGang.FrmID == undefined) {
					this.$fun.alert(
						"Seleccione un Capataz para el pesado",
						"warning"
					);
					return;
				}

				//Validamos las jabas restantes
				if (!this.isDifferenceJabas(this.items.WpbJabasQuantity)) {
					return;
				}

				if (
					this.items.WpbJabasQuantity <= 0 ||
					this.items.WpbJabasQuantity.length == 0
				) {
					this.$refs.focusJabasQuantity.error(
						"Debe ingresar Cantidad de Jabas valido"
					);
					isValidated = false;
					return;
				}

				if (this.items.TypeDoor == undefined) {
					this.$fun.alert("Seleccione una puerta..", "warning");
					isValidated = false;
					return;
				}

				if (
					this.items.WpbWeightGross <= 0 ||
					this.items.WpbWeightGross.length == 0
				) {
					this.$refs.focusWeightGross.error(
						"Debe ingresar un Peso Bruto válido"
					);
					isValidated = false;
					return;
				}

				if (
					this.items.WpbWeightJaba <= 0 ||
					this.items.WpbWeightJaba.length == 0 ||
					this.items.WpbWeightJaba == undefined
				) {
					this.$fun.alert(
						"Debe Configurar el Precio por Jaba, Contacte con el Area de Producción",
						"warning"
					);
					isValidated = false;
					return;
				}

				if (
					this.items.WpbWeightPallet < 0 ||
					this.items.WpbWeightPallet.length == 0
				) {
					this.$refs.focusWeightPallet.error(
						"Debe ingresar un Peso de Parihuela válido"
					);
					isValidated = false;
					return;
				}

				if (
					this.GetWeightNetPallet <= 0 ||
					this.GetWeightNetPallet.length == 0
				) {
					this.$fun.alert(
						"El Peso neto Calculado es incorrecto",
						"warning"
					);
					isValidated = false;
					return;
				}

				//Margenes de Peso Promedio de Jaba
				var marginPriceAverage = {
					Min: this.valParamMin,
					Max: this.valParamMax
				};
				var WeightAverage = this.GetWeightAverageJaba();

				if (
					WeightAverage < marginPriceAverage.Min ||
					WeightAverage > marginPriceAverage.Max
				) {
					this.$fun.alert(
						"El Peso Promedio Jaba Calculado, No esta dentro del rango establecido ==> Min = " +
							marginPriceAverage.Min +
							", Max = " +
							marginPriceAverage.Max +
							", Peso Promedio Calculado = " +
							WeightAverage,
						"warning"
					);
					isValidated = false;
					return;
				}

				return isValidated;
			},

			

			//Grabar Pesada
			Grabar() {
				if (!this.isValidatedAll()) {
					return;
				}

				this.items.RcfID = this.RcfID;
				this.items.RcfGngID = this.viewItemsGang.RcfGngID;
				this.items.SecStatus = 1;
				this.items.UsrCreateID = this.$fun.getUserID();
				this.items.WpbWeightNet = this.GetWeightNetPallet;
				this.items.WpbWeightAverageJaba = this.GetWeightAverageJaba();
				this.items.TypeShip = 1;

				_sWeighedByBatch.save(this.items, this.$fun.getUserID()).then(r => {
					if (r.status == 200) {
						this.$fun.alert("Registrado correctamente", "success");
						this.getWeighedByBatch();
						this.clearAll();
						this.$refs.focusWeightGross.focus();

						

					}
				});
			},

			//Editar una pesada
			edit() {
				if (this.selectedWeighed.length > 0) {
					this.items = this.selectedWeighed[0];
					this.items.WpbID = this.selectedWeighed[0].WpbID;
					//Disminuimos a la Cantidad Total la cantidad que vamos a editar
					if (this.clickEdit == 0) {
						this.sumTotalJaba =
							this.sumTotalJaba -
							this.selectedWeighed[0].WpbJabasQuantity;
						this.clickEdit += 1;
					}
				} else {
					this.$fun.alert("No existen registros", "warning");
				}
			},

			//Eliminar una pesada
			remove() {
				if (this.itemsWeighed.length > 0) {
					var items = this.itemsWeighed[0];

					items.SecStatus = 0;
					items.UsrCreateID = this.$fun.getUserID();

					this.$fun
						.alert("Esta seguro de eliminar el registro ? ", "question")
						.then(val => {
							if (val.value) {
								_sWeighedByBatch
									.save(items, this.$fun.getUserID())
									.then(r => {
										if (r.status == 200) {
											this.$fun.alert(
												"Eliminado correctamente",
												"success"
											);
											this.getWeighedByBatch();
										}
									});
							}
						});
				}
			},

			printQR() {

				if(this.itemsWeighed.length > 0) {

				
				console.log("printQR", this.selectedWeighed[0]);
				var img = document.querySelector("img");

				var Pagelink = "about:blank";
				var pwa = window.open(Pagelink, "_new");
				pwa.document.open();
				pwa.document.write(
					"<html><head><scri" +
						"pt>function step1(){\n" +
						"setTimeout('step2()', 2);}\n" +
						"function step2(){window.print();window.close()}\n" +
						"</scri" +
						"pt><style media='print'>  @media print  { @page { margin-left: 1cm; margin-bottom: 4cm; {} </style></head><body onload='step1()'> \n" +
						"<div class='col col-12 justify-center'>"
				);

				pwa.document.write(
					"<div style='margin: 20px 20px 20px 20px;'><img alt='Scan me!' style='display: block;' src='" +
						img.currentSrc +
						"' /></div>"
				);
				pwa.document.write("<br><br><div ><table border='0'>");

				pwa.document.write(
					"<tr><td>Pallet Nro: </td><td>" +
						this.selectedWeighed[0].WpbNumberPallet + " - " +
						this.selectedWeighed[0].WpbID +
						" </td></tr>"
				);
				pwa.document.write(
					"<tr><td>Lote Produccion: </td><td>" +
						this.objForeman.RcfGngID.RcfID + " - " + this.objForeman.RcfGngID.RcfCode + 
						" </td></tr>"
				);
				
				pwa.document.write(
					"<tr><td>Capataz </td><td>" +
						this.objForeman.RcfGngID.FrmName +
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Guia Cosecha </td><td>" +
						this.objForeman.RcfGngID.RcfNumberReferralGuide +
						//this.objPriceJaba. +
						" </td></tr>",
				);

				pwa.document.write(
					"<tr><td>Productor: </td><td>" +
						this.objForeman.RcfGngID.PrdCardName +
						" </td></tr>"
				);
				
				pwa.document.write(
					"<tr><td>Fundo: </td><td>" +
						this.objForeman.RcfGngID.FagName +
						" || " +
						this.objForeman.RcfGngID.FltName +
						" </td></tr>"
				);

				pwa.document.write(
					"<tr><td>Localidad: </td><td>" +
						this.selectedWeighed[0].TypeZonLocationName +
						" </td></tr>"
				);
				
				pwa.document.write(
					"<tr><td>Cultivo: " +
						this.objForeman.RcfGngID.TypeCultiveName +
						" </td><td> Variedad: " +
						this.objForeman.RcfGngID.VrtName +
						" </td></tr>"
				);
				pwa.document.write(
					"<tr><td>Tipo Cultivo: </td><td>" +
						this.objForeman.RcfGngID.TypeCropName +
						" </td></tr>"
				);

				
				pwa.document.write(
					"<tr><td>Peso Promedio Jaba: </td><td>" +
						this.selectedWeighed[0].WpbWeightAverageJaba +
						" </td></tr>"
				);
				pwa.document.write(
					"<tr><td>Peso Bruto: " +
						this.selectedWeighed[0].WpbWeightGross +
						" </td><td> Peso Neto: " +
						this.selectedWeighed[0].WpbWeightNet +
						" </td></tr>"
				);
				pwa.document.write(
					"<tr><td>Puerta: </td><td>" +
						this.selectedWeighed[0].TypeDoorName +
						" </td></tr>"
				);

				pwa.document.write(
					"<tr><td>Certificaciones: </td><td>" +
						this.objForeman.RcfGngID.TcDescription +
						" </td></tr>"
				);

				pwa.document.write(
					"<tr><td>Cant. Jabas: </td><td>" +
						this.selectedWeighed[0].WpbJabasQuantity +
						" </td></tr>"
				);

				pwa.document.write("</table></div>");

				pwa.document.write("</div></body></html>");
				pwa.document.close();

				}
			}
		},

		watch: {
			itemsWeighed() {
				this.sumTotalJaba = this.GetSumQuantityJabas();

				if(this.itemsWeighed.length == 1 || this.itemsWeighed.length == 0) {
					this.loadData();
				}

				this.sumTotalWeight()
			},

			GetWeightNetPallet() {
				this.RcfWeightProm = this.GetWeightAverageJaba();
			},

			objForeman() {
				//console.log(this.RcfGngID);
				//this.inputForeman(this.RcfGngID);
			},
			sumTotalJaba(){
				if(this.viewItemsGang.FrmQuantityReal == this.sumTotalJaba){
					this.loadData();
				}
			}
		},

		computed: {
			//Calcular el peso Neto
			GetWeightNetPallet() {
				if (this.items !== undefined) {
					var QuantityJabas = 0,
						WeightGross = 0,
						WeightPallet = 0,
						WeightJaba = 0,
						WeightNet = 0;
					QuantityJabas = this.items.WpbJabasQuantity;
					WeightGross = this.items.WpbWeightGross;
					WeightPallet = this.items.WpbWeightPallet;
					WeightJaba = this.items.WpbWeightJaba;

					WeightNet =
						WeightGross - WeightPallet - WeightJaba * QuantityJabas;
				}

				return WeightNet;
			}
		},

		created() {
			this.loadData();
		}
	};
</script>
