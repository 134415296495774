import Service from "../Service";

const resource = "weighedByBatch/"

export default {

    getWeighedBatch(obj, requestID) {
        return Service.post(resource + "getWeighedBatch", obj, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    getHarvestGuide(obj = {}, requestID) {
        return Service.post(resource + "getHarvestGuide", obj, {
            params: { requestID: requestID },
        });
    },

    getDoor(obj, requestID) {
        return Service.post(resource + "getDoor", obj, {
            params: { requestID: requestID },
        });
    },

    getDoorAll(obj, requestID) {
        return Service.post(resource + "getDoorAll", obj, {
            params: { requestID: requestID },
        });
    },

    /* getGuideForeman(requestID, HvsID) {
        // alert("service", RcsID)
        return Service.post(resource + "getGuideForeman", "", {
            params: { requestID: requestID, HvsID: HvsID },
        });
    },
 */


}