import Service from "../../Service";

const resource = "samplequality/"

export default {
    pagination(dt, requestID) {
        return Service.post(resource + "pagination", dt, {
            params: { requestID: requestID },
        });
    },
    /* find(RcfID, NtpForemanID, isSample, requestID) {
         return Service.post(resource + "find", {}, {
             params: { requestID: requestID, RcfID: RcfID, NtpIDForeman: NtpForemanID, isSample: isSample },
         });
     },*/
    saveFooter(alh, requestID) {
        return Service.post(resource + "saveFooter", alh, {
            params: { requestID }
        });
    },
    save(alh, requestID) {
        return Service.post(resource + "save", alh, {
            params: { requestID }
        });
    },

    savedetail(obj, requestID) {
        return Service.post(resource + "savedetail", obj, {
            params: { requestID }
        });
    },

    savehead(obj, requestID) {
        return Service.post(resource + "savehead", obj, {
            params: { requestID }
        });
    },

    listhead(obj, requestID) {
        return Service.post(resource + "listhead", obj, {
            params: { requestID }
        });
    },

    sumgroup(RcfID, requestID) {
        return Service.post(resource + "sumgroup", {}, {
            params: { RcfID, requestID }
        });
    },

    searchbyhead(SqhID, requestID) {
        return Service.post(resource + "searchbyhead", {}, {
            params: { SqhID, requestID }
        });
    },
    saveswitchv(switchv, requestID) {

        return Service.post(resource + "saveswitchv", switchv, {
            params: { requestID }
        });
    },
    findSample(samplequality, requestID) {
        return Service.post(resource + "findSample", samplequality, {
            params: { requestID }
        });
    },
    saveObsBrix(samplequality, requestID) {
        return Service.post(resource + "saveObsBrix", samplequality, {
            params: { requestID }
        });
    }

}